<template>
  <div>
    <VasionSchedulersPage title="Content Move" />
  </div>
</template>

<script>
export default {
  name: 'AutomationEngineContentMove',
  data: function () {
    return {
    }
  },
  computed: {
  },
  async created() {
    await this.$store.dispatch('automationEngine/getMoveFieldComparisons')
  },
  methods: { },
}
</script>

<style lang="scss" scoped>
</style>
